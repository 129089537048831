import { environment } from 'src/environments/environment';
import { ContactModel } from './../../models/contactModel';
import { Component, OnInit } from '@angular/core';
import { CMSApiService } from '@services/api/cms-api.service';
import { GlobalService } from '@services/global.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { DateTime } from 'luxon';
import { ScheduleService } from '../../services/schedule.service';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { NotificationService } from '@services/index';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  month = '';
  title = 'Events';
  content;
  contentStore;
  isLoading = false;
  currentEventId;
  currentEvent;
  contact: ContactModel;
  reservedList: Array<string>;
  hasContact = false;
  errorMessage = '';
  utm = '';
  campaignName = '';
  campaignDate = '';
  cmsURL = '';
  cmsURLAssets = '';
  weeks: Array<number>;
  currentWeek = '0';
  whiteLabel;

  constructor(
    private scheduleService: ScheduleService,
    private cookieService: CookieService,
    private cms: CMSApiService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private globalService: GlobalService,
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.whiteLabel = environment.whiteLabel;
    this.cmsURL = environment.cmsURL;
    this.cmsURLAssets = environment.cmsURLAssets;

    this.route.queryParamMap.subscribe(queryParams => {
      this.utm = queryParams.get('utm');
      this.campaignName = queryParams.get('c');
      this.campaignDate = queryParams.get('d');
    });

    this.loadMetaData();

    this.load();
    this.contact = new ContactModel();
    this.contact.slo = '';
    this.reservedList = new Array<string>();
    const contactString = sessionStorage.getItem('ck-contact');
    if (contactString) {
      this.contact = JSON.parse(contactString);
      this.hasContact = true;
    }

    const reservedListStore = this.cookieService.get('ck-reserved-list');
    if (reservedListStore) {
      this.reservedList = JSON.parse(reservedListStore);
    }

    this.getCurrentMonth();
  }

  loadMetaData() {
    const title = 'Join Free Online Events | Social and Wellness | OneClick Chat ';
    const description = 'Our free-to-join online events include chair yoga, socials, technology courses, and more. ';

    this.titleService.setTitle(title);
    this.metaTagService.updateTag(
      { name: 'description', content: description }
    );
  }

  load() {
    this.weeks = [];

    this.isLoading = true;
    this.cms.getToken().subscribe((retToken) => {
      this.globalService.cmsToken = retToken.access_token;
      this.cms.getEvents(retToken.access_token).subscribe((ret) => {
        console.log(ret);
        this.isLoading = false;
        this.content = ret.items;
        // console.log(this.content);
        this.isLoading = false;
        const currentDateTime = DateTime.local();

        const isoEventDateP60: DateTime = currentDateTime.minus({ minutes: 120 });

        this.content.forEach(contenItem => {
          const isoEventDate = DateTime.fromISO(contenItem.data.schedule.iv);
          if (isoEventDate <= isoEventDateP60) {
            contenItem.status = 'Expired';
            contenItem.expanded = false;
          }
          contenItem.week = isoEventDate.weekNumber;
          this.weeks.push(contenItem.week);
        });

        this.weeks = this.weeks.filter((v, i, a) => a.indexOf(v) === i);
        this.contentStore = this.content;
      });
    });
  }

  filter() {
    if (this.currentWeek === '0') {
      this.content = this.contentStore;
    } else {
      const internalCurrentWeek = Number(this.currentWeek) + 4;
      this.content = this.contentStore.filter(c => String(c.week) === String(internalCurrentWeek));
    }
  }

  open(event, reserve) {
    this.currentEventId = event.id;
    this.currentEvent = event.data;
    // console.log(this.currentEvent);
    this.modalService.open(reserve, { ariaLabelledBy: '' }).result.then((result) => {
    }, (reason) => {
    });
  }

  hasReserved(event): boolean {
    let ret = false;

    const eventId = event.id;

    if (this.reservedList) {
      if (this.reservedList.includes(eventId)) {
        ret = true;
      }
    }

    return ret;
  }

  validate(): boolean {
    let ret = true;

    if (!this.contact.name) {
      this.errorMessage = 'Please enter your first and last name';
      ret = false;
      return;
    }

    // const regexp = new RegExp('/^[a-zA-Z]+ [a-zA-Z]+$/');
    // if (!regexp.test(this.contact.name)) {
    //   this.errorMessage = 'Please enter both your first and last name';
    //   ret = false;
    //   return;
    // }

    if (!this.contact.email) {
      this.errorMessage = 'Please enter your email address';
      ret = false;
      return;
    }

    if (!this.contact.email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      this.errorMessage = 'Please enter a valid email';
      ret = false;
      return;
    }

    if (this.whiteLabel !== 'lab') {
      if (!this.contact.slo) {
        this.errorMessage = 'Please select your affiliated community';
        ret = false;
        return;
      }
    }

    this.errorMessage = '';

    return ret;
  }

  ok() {

    if (!this.validate()) {
      return;
    }

    const name = this.contact.name;
    const email = this.contact.email;
    const slo = this.contact.slo;
    const eventName = this.currentEvent.title.iv;
    const url = this.currentEvent.url.iv;
    const schedule = this.currentEvent.schedule.iv;
    const datePipe = new DatePipe('en-US');
    const date = this.getDate(this.currentEvent.schedule.iv);

    // tslint:disable-next-line: max-line-length
    this.scheduleService.sendWithUTM(email, name, eventName, url, schedule, date, slo, this.utm, this.campaignName, this.campaignDate).subscribe((ret) => {
      if (ret) {
        if (!this.reservedList.includes(this.currentEventId)) {
          this.reservedList.push(this.currentEventId);
          this.cookieService.set('ck-reserved-list', JSON.stringify(this.reservedList));
          this.hasContact = true;
          // console.log('sent');
          this.notificationService.success("Request sent");
        }
      }
    });

    const contactString = JSON.stringify(this.contact);
    sessionStorage.setItem('ck-contact', contactString);
    this.modalService.dismissAll('');
    this.contact = new ContactModel();

  }

  reserveWithEvent(event) {
    const contactInfo = sessionStorage.getItem('ck-contact');
    const currentContact: ContactModel = JSON.parse(contactInfo);

    this.contact = currentContact;

    this.currentEventId = event.id;
    this.currentEvent = event.data;
    const name = this.contact.name;
    const email = this.contact.email;
    const slo = this.contact.slo;
    const eventName = this.currentEvent.title.iv;
    const url = this.currentEvent.url.iv;
    const schedule = this.currentEvent.schedule.iv;

    const datePipe = new DatePipe('en-US');
    const date = this.getDate(this.currentEvent.schedule.iv);

    // tslint:disable-next-line: max-line-length
    this.scheduleService.sendWithUTM(email, name, eventName, url, schedule, date, slo, this.utm, this.campaignName, this.campaignDate).subscribe((ret) => {
      if (ret) {
        if (!this.reservedList.includes(this.currentEventId)) {
          this.reservedList.push(this.currentEventId);
          this.cookieService.set('ck-reserved-list', JSON.stringify(this.reservedList));
          this.hasContact = true;
        }
      }
    });

    const contactString = JSON.stringify(this.contact);
    sessionStorage.setItem('ck-contact', contactString);
    this.modalService.dismissAll('');
  }

  cancelEvent(event) {
    this.currentEventId = event.id;
    this.currentEvent = event.data;
    const email = this.contact.email;
    const eventName = this.currentEvent.title.iv;
    const schedule = this.currentEvent.schedule.iv;

    this.scheduleService.cancel(email, eventName, schedule).subscribe((ret) => {
      if (ret) {
        if (this.reservedList.includes(this.currentEventId)) {
          const index = this.reservedList.indexOf(this.currentEventId, 0);
          if (index > -1) {
            this.reservedList.splice(index, 1);
          }
        }
      }
    });

  }

  cancelWindow() {
    this.errorMessage = '';
    this.modalService.dismissAll('');
  }

  getDate(date: string): string {
    let ret = '';
    const isoDate = DateTime.fromISO(date);
    const local = isoDate.toLocal();
    ret = local.toFormat('EEEE MMMM d @ h:mm a  ZZZZ');
    ret = ret.replace('@', 'at');
    return ret;
  }

  getDateFormatted(date: string, format: string): string {
    let ret = '';
    const isoDate = DateTime.fromISO(date);
    const local = isoDate.toLocal();
    ret = local.toFormat(format);
    return ret;
  }

  joinNow(event) {
    let url = 'https://' + event.data.url.iv;

    const contactInfo = sessionStorage.getItem('ck-contact');
    if (contactInfo) {
      const currentContact: ContactModel = JSON.parse(contactInfo);
      if (currentContact) {
        let fName = '';
        let lName = '';
        let email = '';

        if (currentContact.name.split(' ').length > 1) {
          fName = currentContact.name.split(' ')[0];
          lName = currentContact.name.split(' ')[1];
          email = currentContact.email;
        } else {
          fName = currentContact.name;
          lName = '';
          email = currentContact.email;
        }
        url = 'https://' + event.data.url.iv;
      }
    }

    console.log(url);
    window.location.href = url;
  }

  isNow(event): boolean {
    const isoEventDate = DateTime.fromISO(event.data.schedule.iv);

    const isoEventDateM30: DateTime = isoEventDate.minus({ minutes: 30 });
    const isoEventDateP60: DateTime = isoEventDate.plus({ minutes: 60 });

    const currentDateTime = DateTime.local();
    // const currentDateTime: DateTime = DateTime.fromISO('2020-10-02T19:00:00Z');

    const timeDiff = currentDateTime.diff(isoEventDateM30, 'minutes').toObject();
    const minutes = timeDiff.minutes;

    if (minutes >= -30 && minutes <= 60) {
      return true;
    } else {
      return false;
    }
  }

  expand(contenItem) {
    if (contenItem.expanded) {
      contenItem.expanded = false;
    } else {
      contenItem.expanded = true;
    }
  }

  getCurrentMonth() {
    const currentDateTime = DateTime.local();
    this.month = currentDateTime.monthLong;
  }

}
